import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PriceList from "../components/PriceList/priceList"
import styles from "./cennik.module.scss"
import AOS from "aos"
import SEO from "../components/seo"
import "aos/dist/aos.css"

const Cennik = ({ data }) => {
  const fizykoterapia = data.allStrapiCennik.edges.filter(
    document => document.node["typ_zabiegu"] === "Fizykoterapia"
  )
  const hydroterapia = data.allStrapiCennik.edges.filter(
    document => document.node["typ_zabiegu"] === "Hydroterapia"
  )
  const masaz = data.allStrapiCennik.edges.filter(
    document => document.node["typ_zabiegu"] === "Masaz"
  )
  const kinezyterapia = data.allStrapiCennik.edges.filter(
    document => document.node["typ_zabiegu"] === "Kinezyterapia"
  )
  const cieplolecznictwo = data.allStrapiCennik.edges.filter(
    document => document.node["typ_zabiegu"] === "Cieplolecznictwo"
  )

  useEffect(() => {
    AOS.init({
      duration: 500,
    })
  }, [])

  return (
    <Layout>
      <SEO
        title="Cennik"
        keywords= {Array(data.allStrapiCennik.edges.map((node) => node.node.nazwa)).join(" ")}
      />
      <div className={styles.container}>
        <h1 className={styles.mainHeader} data-aos="fade-left">
          CENNIK
        </h1>
        <ul className={styles.list}>
          <PriceList items={fizykoterapia} title="Fizykoterapia" />
          <PriceList items={hydroterapia} title="Hydroterapia" />
          <PriceList items={masaz} title="Masaż" />
          <PriceList items={kinezyterapia} title="Kinezyterapia" />
          <PriceList items={cieplolecznictwo} title="Ciepłolecznictwo" />
        </ul>
      </div>
    </Layout>
  )
}

export default Cennik

export const query = graphql`
  query indexQuery {
    allStrapiCennik {
      edges {
        node {
          id
          nazwa
          cena
          typ_zabiegu
        }
      }
    }
  }
`
