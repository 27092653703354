import React from "react"
import styles from "./priceTag.module.scss"
const PriceTag = props => {
  return (
    <li key={props.id} className={styles.priceTag}>
      <h3 className={styles.title}>
        {props.title}
      </h3>
      <div className={styles.price}>{props.price.toFixed(2)}zł</div>
    </li>
  )
}
export default PriceTag
