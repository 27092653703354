import React from "react"
import PriceTag from "./PriceTag/priceTag"
import styles from "./priceList.module.scss"


const PriceList = props => {
  return (
    <li data-aos='fade-up'>
     <h2 className={styles.title} >{props.title}</h2>
      <ul className={styles.pricesList}>
        {props.items.map(document => (
          <PriceTag
           key = {document.node.id}
            id={document.node.id}
            title={document.node.nazwa}
            price={document.node.cena}
            type={document.node["typ_zabiegu"]}
          />
        ))}
      </ul>
    </li>
  )
}

export default PriceList
